import React from "react";
import WelcomeScreen from "../containers/WelcomeScreen";
import Login from "../containers/auth/Login";
import TODO from "../containers/TODO";
import Tenant from "../containers/Tenant";
import { RouteComponentProps } from "react-router";
import LineEdit from "../containers/settings/LineEdit";
import Guest from "../containers/Guest";
import GuestEdit from "../containers/GuestEdit";
import TenantList from "../containers/TenantList";
import Activate from "../containers/Activate";
import User from "../containers/User";
import Room from "../containers/Room";
import RoomEdit from "../containers/RoomEdit";

export interface RouteInfo {
    name?: string;
    path?: string;
    component?: React.ComponentClass<any, any>;
    hideLeftNav?: boolean;
    disableRootTenant?: boolean;
    children?: RouteInfo[];
}

const routes: RouteInfo[] = [
    {
        path: "/",
        hideLeftNav: true,
        component: WelcomeScreen,
    },
    {
        path: "/auth/login",
        hideLeftNav: true,
        component: Login,
    },
    {
        path: "/activate/:tmpToken",
        component: Activate,
        hideLeftNav: true,
    },
    {
        name: "テナント管理",
        path: "/tenants",
        component: TenantList,
        disableRootTenant: false,
    },
    {
        path: "/tenants/:tenantId",
        component: Tenant,
        hideLeftNav: true,
    },
    {
        name: "テナント情報",
        path: "/tenants/:tenantId",
        component: Tenant,
        disableRootTenant: true,
    },
    {
        name: "ルーム管理",
        path: "/tenants/:tenantId/rooms",
        component: Room,
        disableRootTenant: true,
    },
    {
        path: "/tenants/:tenantId/rooms/:roomId",
        component: RoomEdit,
        hideLeftNav: true,
        disableRootTenant: true,
    },
    {
        name: "ゲスト管理",
        path: "/tenants/:tenantId/guests",
        component: Guest,
        disableRootTenant: true,
    },
    {
        path: "/tenants/:tenantId/guests/:guestId",
        component: GuestEdit,
        hideLeftNav: true,
        disableRootTenant: true,
    },
    {
        name: "設定",
        path: "/settings",
        children: [
            {
                name: "LINE",
                path: "/tenants/:tenantId/settings/line",
                component: LineEdit,
                disableRootTenant: true,
            },
            {
                path: "/tenants/:tenantId/settings/line/edit",
                component: LineEdit,
                hideLeftNav: true,
                disableRootTenant: true,
            },
            {
                name: "ユーザー",
                path: "/tenants/:tenantId/users",
                component: User,
            },
            {
                name: "APIキー",
                path: "/tenants/:tenantId/keys",
                component: TODO,
            },
        ],
    },
];

export default routes;

export type WithRouteParamProps = RouteComponentProps<{
    tenantId: string;
    mode: "create" | "edit";
    lineId: string;
    lineChannelId: string;
    lineRichMenuId: string;
    guestId: string;
    roomId: string;
}>;
