import React from "react";
import {inject, observer} from "mobx-react";
import {AuthStore} from "../stores/AuthStore";
import {Descriptions, Divider, PageHeader, Spin, Typography} from "antd";
import {WithRouteParamProps} from "../constants/Routes";
import {RoomState, RoomStore, RoomWithMessageModel} from "../stores/RoomStore";
import {Link} from "react-router-dom";
import {toJS} from "mobx";

export interface Props extends WithRouteParamProps {
    AuthStore?: AuthStore;
    RoomStore?: RoomStore;
}

@inject("AuthStore", "RoomStore")
@observer
export default class RoomEdit extends React.Component<Props, {}> {
    public componentDidMount() {
        this.props.RoomStore!.get(this.props.match.params.tenantId, this.props.match.params.roomId);
    }

    public componentWillUnmount() {
        this.props.RoomStore!.resetEditItem();
    }

    public render() {
        const item = this.props.RoomStore!.editItem as RoomWithMessageModel;

        if (!item.id) {
            return <Spin size={"large"} />
        }

        return (
            <div>
                <PageHeader title={<Typography.Title>ルーム詳細</Typography.Title>}>
                    <Descriptions column={2}>
                        <Descriptions.Item label={"ID"}>{item.id}</Descriptions.Item>
                        <Descriptions.Item label={"問い合わせゲスト"}>
                            <Link to={`/tenants/${this.props.match.params.tenantId}/guests/${item.guest}`}>{item.guest}</Link>
                        </Descriptions.Item>

                        <Descriptions.Item label={"ルーム名"}>{item.name}</Descriptions.Item>
                        <Descriptions.Item label={"対応ユーザー"}>
                            <Link to={`/tenants/${this.props.match.params.tenantId}/users/${item.user}`}>{item.user}</Link>
                        </Descriptions.Item>

                        <Descriptions.Item label={"概要"}>{item.description}</Descriptions.Item>
                        <Descriptions.Item label={"開始日時"}>{new Date(item.created!).toLocaleString()}</Descriptions.Item>

                        <Descriptions.Item label={"状態"}>{item.state === RoomState.OPEN ? "オープン" : "クローズ" /* FIXME */}</Descriptions.Item>
                        <Descriptions.Item label={"更新日時"}>{new Date(item.updated!).toLocaleString()}</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <div style={{marginTop: "2rem", marginBottom: "1rem"}}>
                    // TODO: チャット風にいい感じに表示する
                </div>
                {(toJS(item.messages) || []).map((message) => {
                    if (message.user) {
                        return (
                            <div>
                                <Divider />
                                <div>
                                    ユーザー <Link to={`/tenants/${this.props.match.params.tenantId}/users/${item.user}`}>{item.user}</Link>:
                                </div>
                                <div>
                                    {message.body}
                                </div>
                            </div>
                        );
                    }
                    return (
                        <div>
                            <Divider />
                            <div>
                                ゲスト <Link to={`/tenants/${this.props.match.params.tenantId}/guests/${item.guest}`}>{item.guest}</Link>:
                            </div>
                            <div>
                                {message.body}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}
