import {action, computed, observable} from "mobx";
import {ModelBase, OperationMode, OperationState, StoreBase} from "./Base";
import {events} from "./index";

export enum Plan {
    IOTA,
    ROOT,
    FREE,
}

export const PlanString = [
    "-",
    "system",
    "βフリープラン",
];

export interface TenantModel extends ModelBase {
    name: string;
    description: string;
    plan: Plan;
}

export class TenantStore extends StoreBase {
    @observable
    public tenants: { [index: string]: TenantModel };

    @observable
    public selectedId: string;

    @observable
    public isVisibleEditDrawer: boolean;

    @observable
    public editTenant: TenantModel;

    constructor() {
        super();

        this.tenants = {};
        this.editTenant = {} as TenantModel;
        this.isVisibleEditDrawer = false;

        // const current = location.pathname.match(/\/tenants\/([^/]*)/);
        // if (current) {
        //     console.log(current);
        //     localStorage.tenant = current[1];
        // }
        // this.selectedId = localStorage.tenant || "-";
        this.selectedId = "-";
    }

    @action
    public async getTenants() {
        if (!this.isLoggedIn) {
            return;
        }
        this.setState(OperationState.RUNNING);

        try {
            const response = await fetch(this.apiPath(`v1/tenants`), {
                method: "GET",
                headers: this.generateFetchHeader(),
            });

            if (response.status !== 200) {
                throw new Error();
            }
            const tenants: TenantModel[] = await response.json();
            this.tenants = {
                ...this.tenants,
                ...tenants.reduce((obj, tenant) => ({...obj, [tenant.id]: tenant}), {}),
            };

            this.setState(OperationState.DONE);
        } catch (e) {
            this.tryShowToast("テナント取得に失敗しました", "error");
            console.error(e);
            this.setState(OperationState.ERROR);
        }
    }

    @action
    public async getTenant(id: string) {
        if (!this.isLoggedIn) {
            return;
        }
        this.setState(OperationState.RUNNING);

        try {
            const response = await fetch(this.apiPath(`v1/tenants/${id}`), {
                method: "GET",
                headers: this.generateFetchHeader(),
            });

            if (response.status !== 200) {
                throw new Error();
            }
            const tenant: TenantModel = await response.json();
            this.tenants = {
                ...this.tenants,
                [tenant.id]: tenant,
            };

            this.setState(OperationState.DONE);
        } catch (e) {
            this.tryShowToast("テナント取得に失敗しました", "error");
            console.error(e);
            this.setState(OperationState.ERROR);
        }
    }

    @action
    public async postTenants(name: string, description: string, plan: number) {
        this.setState(OperationState.RUNNING);

        try {
            if (name === "" || !Plan[plan]) {
                throw new Error();
            }

            const response = await fetch(this.apiPath("v1/tenants"), {
                method: "POST",
                headers: this.generateFetchHeader(),
                body: JSON.stringify({
                    name,
                    description,
                    plan,
                }),
            });

            if (response.status !== 200) {
                throw new Error();
            }

            this.setState(OperationState.DONE);
            this.getTenants();
        } catch (e) {
            this.tryShowToast("テナント作成に失敗しました", "error");
            console.error(e);
            this.setState(OperationState.ERROR);
        }
    }

    @action
    public select(id: string) {
        this.selectedId = id;
        // localStorage.setItem("tenant", id);
        events.emit("changeTenantId", id);
    }

    @computed
    public get selected() {
        return this.tenants[this.selectedId] || (({} as any) as TenantModel);
    }

    @action
    public async putTenant(id: string, name: string, description: string, plan: number) {
        this.setMode(OperationMode.UPDATE);
        this.setState(OperationState.RUNNING);

        try {
            if (name === "" || !Plan[plan]) {
                throw new Error();
            }

            const response = await fetch(this.apiPath(`v1/tenants/${id}`), {
                method: "PUT",
                headers: this.generateFetchHeader(),
                body: JSON.stringify({
                    name,
                    description,
                    plan,
                }),
            });

            if (response.status !== 200) {
                throw new Error();
            }

            this.getTenant(id);
            this.setState(OperationState.DONE);
        } catch (e) {
            this.tryShowToast("テナント編集に失敗しました", "error");
            console.error(e);
            this.setState(OperationState.ERROR);
        }
    }

    @action
    public async showEditDrawer(id: string) {
        await this.getTenant(id);
        this.editTenant = this.tenants[id];
        this.isVisibleEditDrawer = true;
    }

    @action
    public async closeEditDrawer(shouldUpdate: boolean) {
        if (!shouldUpdate) {
            this.isVisibleEditDrawer = false;
            this.editTenant = {} as TenantModel;
            return;
        }

        const { id, name, plan, description } = this.editTenant;
        await this.putTenant(id, name, description, plan);
        if (this.state === OperationState.DONE) {
            this.isVisibleEditDrawer = false;
            this.editTenant = {} as TenantModel;
        }
    }

    @action
    public async updateEditTenant(tenant: TenantModel) {
        this.editTenant = { ...tenant };
    }
}
