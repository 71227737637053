import { action, observable } from "mobx";
import { ModelBase, OperationMode, OperationState, StoreBase } from "./Base";

export enum GuestType {
    WEB = 1,
    LINE,
}

export enum GuestBlockState {
    UNBLOCKED = 1,
    BLOCKED,
}

export interface GuestModel extends ModelBase {
    tenant: string;
    type: GuestType;
    line_uid: string;
    name: string;
    avatar: string;

    status_message: string;
    language: string;
    blocked: GuestBlockState;
}

export class GuestStore extends StoreBase {
    @observable
    public guests: GuestModel[];

    @observable
    public editGuest: GuestModel;

    constructor() {
        super();

        this.guests = [];
        this.editGuest = {} as GuestModel;
    }

    @action
    public async getGuests(tenantId: string) {
        if (!this.isLoggedIn) {
            return;
        }
        this.setMode(OperationMode.READ);
        this.setState(OperationState.RUNNING);

        try {
            const response = await fetch(this.apiPath(`v1/tenants/${tenantId}/guests`), {
                method: "GET",
                headers: this.generateFetchHeader(),
            });

            if (response.status !== 200) {
                throw new Error();
            }
            this.guests = await response.json();

            this.setState(OperationState.DONE);
        } catch (e) {
            this.tryShowToast("ゲスト取得に失敗しました", "error");
            console.error(e);
            this.setState(OperationState.ERROR);
        }
    }

    @action
    public async getGuest(tenantId: string, guestId: string) {
        if (!this.isLoggedIn) {
            return;
        }
        this.setMode(OperationMode.CREATE);
        this.setState(OperationState.RUNNING);

        try {
            const response = await fetch(this.apiPath(`v1/tenants/${tenantId}/guests/${guestId}`), {
                method: "GET",
                headers: this.generateFetchHeader(),
            });

            if (response.status !== 200) {
                throw new Error();
            }

            this.editGuest = await response.json();
            this.setState(OperationState.DONE);
        } catch (e) {
            this.tryShowToast("ゲスト取得に失敗しました", "error");
            console.error(e);
            this.setState(OperationState.ERROR);
        }
    }

    @action updateEditGuest(guest: GuestModel) {
        this.editGuest = guest;
    }
}
