import { action, observable } from "mobx";
import { ModelBase, OperationMode, OperationState, StoreBase } from "./Base";

export interface LineModel extends ModelBase {
    tenant: string;
    channel_id: string;
    channel_secret: string;
    long_term_token: string;
}

export class SettingStore extends StoreBase {
    @observable
    public line: LineModel;

    @observable
    public editLine: LineModel;

    constructor() {
        super();

        this.line = {} as LineModel;
        this.editLine = {} as LineModel;
    }

    @action
    public async getLine(tenantId: string) {
        this.setMode(OperationMode.READ);
        this.setState(OperationState.RUNNING);

        try {
            const response = await fetch(this.apiPath(`v1/tenants/${tenantId}/settings/line`), {
                method: "GET",
                headers: this.generateFetchHeader(),
            });

            if (response.status !== 200) {
                throw new Error();
            }
            this.line = this.editLine = await response.json();

            this.setState(OperationState.DONE);
        } catch (e) {
            this.tryShowToast("LINE設定取得に失敗しました", "error");
            console.error(e);
            this.setState(OperationState.ERROR);
        }
    }

    @action
    public async putLine(tenantId: string, channelId: string, channelSecret: string, longTermToken: string) {
        this.setMode(OperationMode.UPDATE);
        this.setState(OperationState.RUNNING);

        try {
            const response = await fetch(this.apiPath(`v1/tenants/${tenantId}/settings/line`), {
                method: "PUT",
                headers: this.generateFetchHeader(),
                body: JSON.stringify({
                    channel_id: channelId,
                    channel_secret: channelSecret,
                    long_term_token: longTermToken,
                }),
            });

            if (response.status !== 200) {
                throw new Error();
            }

            this.setState(OperationState.DONE);
        } catch (e) {
            this.tryShowToast("LINE設定保存に失敗しました", "error");
            console.error(e);
            this.setState(OperationState.ERROR);
        }
    }

    @action updateEditLine(line: LineModel) {
        this.editLine = line;
    }
}
