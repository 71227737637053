import React from "react";
import {toJS} from "mobx";
import {inject, observer} from "mobx-react";
import {AuthStatus, AuthStore} from "../stores/AuthStore";
import {Redirect} from "react-router";
import {Plan, TenantStore} from "../stores/TenantStore";
import {Spin} from "antd";

export interface Props {
    AuthStore?: AuthStore;
    TenantStore?: TenantStore;
}

@inject("AuthStore", "TenantStore")
@observer
export default class WelcomeScreen extends React.Component<Props, {}> {
    public componentDidMount() {
        if (this.props.AuthStore!.user?.tenant && !this.props.TenantStore!.tenants[this.props.AuthStore!.user?.tenant]) {
            this.props.TenantStore!.getTenant(this.props.AuthStore!.user?.tenant);
        }
    }

    public render() {
        console.log("welcomescreen");

        if (toJS(this.props.AuthStore!.authStatus) === AuthStatus.UNAUTHORIZED) {
            return <Redirect to={"/auth/login"} />;
        }

        if (!this.props.TenantStore!.tenants[this.props.AuthStore!.user?.tenant]) {
            return <Spin size={"large"} />;
        }

        if (toJS(this.props.AuthStore!.authStatus) === AuthStatus.AUTHORIZED) {
            if (this.props.TenantStore!.tenants[this.props.AuthStore!.user?.tenant]?.plan === Plan.ROOT) {
                return <Redirect to={`/tenants`} />;
            }
            return <Redirect to={`/tenants/${this.props.TenantStore!.selectedId}`} />;
        }

        return <Spin size={"large"} />;
    }
}
