import React from "react";
import { style } from "typestyle";
import * as jdenticon from "jdenticon";

export interface IProps extends React.HTMLProps<HTMLDivElement> {
    source: string;
}

const styles = {
    root: style({
        $nest: {
            "&>svg": {
                width: "100%",
                height: "100%",
            },
        },
    }),
};

export default class Identicon extends React.Component<IProps, {}> {
    public shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<{}>, nextContext: any) {
        return this.props.source != nextProps.source;
    }

    public render() {
        return (
            <div
                {...this.props}
                className={styles.root}
                style={{width: this.props.size, height: this.props.size}}
                dangerouslySetInnerHTML={{
                    __html: (jdenticon as any).toSvg(this.props.source, this.props.size || 1024),
                }}
            />
        );
    }
}
