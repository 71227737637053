import React from "react";
import {Avatar, Form, PageHeader, Spin, Typography} from "antd";
import {inject, observer} from "mobx-react";
import FlexCenter from "../components/FlexCenter";
import {WithRouteParamProps} from "../constants/Routes";
import {OperationMode, OperationState} from "../stores/Base";
import {Redirect} from "react-router";
import {GuestBlockState, GuestStore} from "../stores/GuestStore";
import {style} from "typestyle";
import {TenantStore} from "../stores/TenantStore";

export interface Props extends WithRouteParamProps {
    TenantStore?: TenantStore;
    GuestStore?: GuestStore;
}

export interface State {
    edit: boolean;
}

const styles = {
    nameContainer: style({
        display: "flex",
    }),
    elementContainer: style({
        display: "flex",
        alignItems: "baseline",
        marginBottom: 0,
    }),
};

@inject("TenantStore", "GuestStore")
@observer
export default class GuestEdit extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            edit: false,
        };
    }

    public componentDidMount() {
        this.onMount(this.props);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>) {
        this.onMount(nextProps);
    }

    private onMount(props: Props) {
        if (!props.match.params.guestId) {
            return;
        }

        props.GuestStore!.getGuest(props.match.params.tenantId, props.match.params.guestId);
    }

    public render() {
        if (this.props.GuestStore!.mode === OperationMode.UPDATE || this.props.GuestStore!.mode === OperationMode.DELETE) {
            if (this.props.GuestStore!.state === OperationState.DONE) {
                this.props.GuestStore!.resetMode();
                this.props.GuestStore!.resetState();
                return <Redirect to={`/tenants/${this.props.TenantStore!.selectedId}/guests`} />;
            }
        }

        const guest = this.props.GuestStore!.editGuest;
        if (!guest) {
            return (
                <FlexCenter>
                    <Spin size={"large"} />
                </FlexCenter>
            );
        }

        // const isEdit = this.props.location.pathname.endsWith("edit");
        // if (isEdit) {
        //     headerExtra = [
        //         <LinkButton
        //             key={0}
        //             to={this.props.location.pathname.replace("/edit", "")}
        //         >
        //             キャンセル
        //         </LinkButton>,
        //         <Button
        //             key={1}
        //             type={"primary"}
        //             onClick={() => this.props.GuestStore!.(
        //                 this.props.match.params.lineId,
        //                 line.channel_id,
        //                 line.channel_secret,
        //                 line.long_term_token,
        //             )}
        //         >
        //             保存
        //         </Button>,
        //     ];
        // } else {
        //     headerExtra = [
        //         <LinkButton
        //             key={0}
        //             buttonProps={{type: "primary"}}
        //             to={`${this.props.location.pathname}/edit`}
        //         >
        //             編集
        //         </LinkButton>,
        //         <Button
        //             key={1}
        //             danger={true}
        //             onClick={() => this.props.SettingStore!.deleteLine(
        //                 this.props.match.params.lineId,
        //             )}
        //         >
        //             削除
        //         </Button>,
        //     ]
        // }

        return (
            <div>
                <PageHeader title={<Typography.Title>ゲスト管理</Typography.Title>} />
                <div>
                    <div className={styles.nameContainer}>
                        <Avatar size={40} icon={<img src={guest.avatar || guest.id} />} />
                        <Typography.Title level={2} copyable>
                            {guest.name}
                        </Typography.Title>
                    </div>
                    <Form.Item className={styles.elementContainer} label={"LINE UID"}>
                        <Typography.Paragraph copyable>{guest.line_uid}</Typography.Paragraph>
                    </Form.Item>
                    <Form.Item className={styles.elementContainer} label={"ステータスメッセージ"}>
                        <Typography.Paragraph copyable>{guest.status_message || "未指定"}</Typography.Paragraph>
                    </Form.Item>
                    <Form.Item className={styles.elementContainer} label={"アバターURL"}>
                        <Typography.Paragraph copyable>{guest.avatar}</Typography.Paragraph>
                    </Form.Item>
                    <Form.Item className={styles.elementContainer} label={"言語"}>
                        <Typography.Paragraph copyable>{guest.language}</Typography.Paragraph>
                    </Form.Item>
                    <Form.Item className={styles.elementContainer} label={"ブロック"}>
                        <Typography.Paragraph>
                            {guest.blocked === GuestBlockState.BLOCKED ? "ブロックされています" : "ブロックされていません"}
                        </Typography.Paragraph>
                    </Form.Item>
                    <Form.Item className={styles.elementContainer} label={"初回友だち追加日時"}>
                        <Typography.Paragraph>{new Date(guest.created).toLocaleString()}</Typography.Paragraph>
                    </Form.Item>
                </div>
            </div>
        );
    }
}
