import React from "react";
import { Button, Drawer, Form, Input, Select } from "antd";
import { inject, observer } from "mobx-react";
import { TenantStore, Plan } from "../stores/TenantStore";
import TextArea from "antd/es/input/TextArea";
import { OperationState } from "../stores/Base";
import { style } from "typestyle";

export interface Props {
    TenantStore?: TenantStore;

    visible: boolean;
    onClose: () => void;
}

interface State {
    name: string;
    description: string;
    plan: Plan;
}

const styles = {
    root: style({
        $nest: {
            "& .ant-drawer-content-wrapper": {
                width: "40%",
                minWidth: 420,
            },
        },
    }),
};

@inject("TenantStore")
@observer
export default class TenantDrawer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            name: "",
            description: "",
            plan: 1,
        };
    }

    private onClose() {
        this.setState({
            name: "",
            description: "",
            plan: 1,
        }, () => {
            this.props.onClose();
        });
    }

    public render() {
        return (
            <Drawer
                title="テナント作成"
                width={""}
                className={styles.root}
                visible={this.props.visible}
                placement={"right"}
                bodyStyle={{ paddingBottom: 80 }}
                closable={false}
                destroyOnClose={true}
                footer={
                    <div
                        style={{
                            textAlign: "right",
                        }}
                    >
                        <Button onClick={() => this.onClose()} style={{ marginRight: 8 }}>
                            キャンセル
                        </Button>
                        <Button
                            loading={this.props.TenantStore!.state === OperationState.RUNNING}
                            disabled={this.state.name === "" || this.state.plan === 0}
                            type="primary"
                            onClick={async () => {
                                await this.props.TenantStore!.postTenants(
                                    this.state.name,
                                    this.state.description,
                                    this.state.plan,
                                );
                                this.onClose();
                            }}
                        >
                            作成
                        </Button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark preserve={false}>
                    <Form.Item
                        name="name"
                        label="テナント名"
                        rules={[{ required: true, message: "テナント名を入力してください" }]}
                    >
                        <Input
                            placeholder="分かりやすく完結な名前がベストです"
                            value={this.state.name}
                            onChange={(event) => this.setState({ name: event.target.value })}
                        />
                    </Form.Item>
                    <Form.Item name="description" label="概要" rules={[{ required: false }]}>
                        <TextArea
                            placeholder="プロジェクトなどのメモを残すことができます"
                            autoSize={{ minRows: 4, maxRows: 10 }}
                            value={this.state.description}
                            onChange={(event) => this.setState({ description: event.target.value })}
                        />
                    </Form.Item>
                    <Form.Item name="plan" label="プラン" rules={[{ required: true, message: "プランを選択してください" }]}>
                        <Select
                            placeholder="料金プランを選択します"
                            onSelect={(event) => this.setState({ plan: parseInt(`${event}`, 10) })}
                        >
                            <Select.Option key={Plan.FREE} value={Plan.FREE}>
                                βフリープラン
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Drawer>
        );
    }
}
