import * as React from "react";
import { style } from "typestyle";

export interface Props {}

const styles = {
    root: style({
        display: "flex",
        justifyContent: "space-between",
    }),
};

export default class Split extends React.Component<Props, {}> {
    public render() {
        return <div className={styles.root}>{this.props.children}</div>;
    }
}
