import React from "react";
import { Redirect } from "react-router";
import { inject, observer } from "mobx-react";
import { Form, Button, Input, Checkbox, Divider } from "antd";
import { style, classes } from "typestyle";
import { Link } from "react-router-dom";
import { AuthStatus, AuthStore } from "../../stores/AuthStore";
import { OperationState } from "../../stores/Base";
import Theme from "../../util/Theme";
import Logo from "../../assets/hogeadmin.logo.color.svg";
import LinkButton from "../../components/LinkButton";

const styles = {
    root: style({
        display: "flex",
        height: "100%",
        flexDirection: "row-reverse",
        flex: 1,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    }),
    left: style({
        width: 320,
        padding: "2rem",
        display: "flex",
        alignItems: "center",
        boxShadow: `1px 1px 12px 0px ${Theme.colors.darkGray}`,
    }),
    stack: style({
        width: "100%",
    }),
    logo: style({
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        marginBottom: "2rem",
        $nest: {
            "& > svg": {
                height: 32,
            },
            "& > span": {
                marginLeft: "0.5em",
            },
        },
    }),
    right: style({
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: Theme.colors.white,
        backgroundColor: Theme.colors.base,
    }),
    pullLeft: style({
        display: "flex",
        justifyContent: "flex-start",
        marginBottom: "1rem",
    }),
    pullRight: style({
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "1rem",
    }),
    button: style({
        backgroundColor: Theme.colors.base,
    }),
    passwordLink: style({
        marginTop: "2rem",
        fontSize: "0.8rem",
        color: "rgb(58,81,192)",
        textDecoration: "underline",
    }),
};

export interface Props {
    AuthStore?: AuthStore;
}

interface State {
    email: string;
    password: string;
    keepSession: boolean;
}

@inject("AuthStore")
@observer
export default class Login extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            keepSession: false,
        };
    }

    public render() {
        if (this.props.AuthStore!.authStatus === AuthStatus.AUTHORIZED) {
            return <Redirect to={"/"} />;
        }

        return (
            <div className={styles.root}>
                <div className={styles.right}>ここに適当な文章</div>
                <div className={styles.left}>
                    <Form className={styles.stack}>
                        <div className={styles.logo}>
                            <Logo />
                            <span>(仮)</span>
                        </div>
                        <Form.Item label="email" name="email" rules={[{ required: true, message: "メールアドレスは必須です" }]}>
                            <Input
                                placeholder="email"
                                value={this.state.email}
                                onChange={(event) => {
                                    this.setState({
                                        email: event.target.value,
                                    });
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="password" name="password" rules={[{ required: true, message: "パスワードは必須です" }]}>
                            <Input.Password
                                placeholder="password"
                                value={this.state.password}
                                onChange={(event) => {
                                    this.setState({
                                        password: event.target.value,
                                    });
                                }}
                            />
                        </Form.Item>

                        <div className={styles.pullLeft}>
                            <Checkbox
                                checked={this.state.keepSession}
                                onChange={(e) => {
                                    this.setState({
                                        keepSession: e.target.checked,
                                    });
                                }}
                            >
                                ログインを30日間記憶する
                            </Checkbox>
                        </div>

                        <Form.Item>
                            <div className={classes(styles.pullRight)}>
                                <Button
                                    loading={this.props.AuthStore!.state === OperationState.RUNNING}
                                    type="primary"
                                    htmlType="submit"
                                    onClick={() => {
                                        const { email, password, keepSession } = this.state;
                                        if (email === "" || password === "") {
                                            return;
                                        }
                                        this.props.AuthStore!.login(email, password, keepSession);
                                    }}
                                >
                                    ログイン
                                </Button>
                            </div>
                        </Form.Item>
                        <Link className={classes(styles.passwordLink, styles.pullRight)} to={"/auth/forgot"}>
                            パスワードを忘れました
                        </Link>
                    </Form>
                </div>
            </div>
        );
    }
}
