import * as React from "react";
import { Link, LinkProps } from "react-router-dom";
import { Button } from "antd";
import { ButtonProps } from "antd/es/button";

export interface Props extends LinkProps {
    buttonProps?: ButtonProps;
}

export default class LinkButton extends React.Component<Props, {}> {
    public render() {
        return (
            <Link {...this.props}>
                <Button {...this.props.buttonProps}>{this.props.children}</Button>
            </Link>
        );
    }
}
