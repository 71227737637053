import React from "react";
import { inject, observer } from "mobx-react";
import { AuthStore } from "../stores/AuthStore";

export interface Props {
    AuthStore?: AuthStore;
}

@inject("AuthStore")
@observer
export default class TODO extends React.Component<Props, {}> {
    public render() {
        return <div>TODO</div>;
    }
}
