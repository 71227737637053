import React from "react";
import {Button, Descriptions, Form, Input, PageHeader, Spin, Typography} from "antd";
import { inject, observer } from "mobx-react";
import LinkButton from "../../components/LinkButton";
import FlexCenter from "../../components/FlexCenter";
import { WithRouteParamProps } from "../../constants/Routes";
import { SettingStore } from "../../stores/SettingStore";
import { OperationMode, OperationState } from "../../stores/Base";
import { Redirect } from "react-router";

export interface Props extends WithRouteParamProps {
    SettingStore?: SettingStore;
}

export interface State {
    edit: boolean;
}

@inject("SettingStore")
@observer
export default class LineEdit extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            edit: false,
        };
    }

    public componentDidMount() {
        this.props.SettingStore!.getLine(this.props.match.params.tenantId);
    }

    public render() {
        if (
            this.props.SettingStore!.mode === OperationMode.CREATE ||
            this.props.SettingStore!.mode === OperationMode.UPDATE ||
            this.props.SettingStore!.mode === OperationMode.DELETE
        ) {
            if (this.props.SettingStore!.state === OperationState.DONE) {
                this.props.SettingStore!.resetMode();
                this.props.SettingStore!.resetState();
                return <Redirect to={`/tenants/${this.props.match.params.tenantId}/settings/line`} />;
            }
        }

        const line = this.props.SettingStore!.editLine;
        if (!line) {
            return (
                <FlexCenter>
                    <Spin size={"large"} />
                </FlexCenter>
            );
        }

        const isCreate = this.props.match.params.lineId === "-";
        const isEdit = isCreate || this.props.location.pathname.endsWith("edit");
        let headerExtra;
        if (isCreate) {
            headerExtra = [
                <Button
                    key={0}
                    type={"primary"}
                    onClick={() => this.props.SettingStore!.putLine(this.props.match.params.tenantId, line.channel_id, line.channel_secret, line.long_term_token)}
                >
                    保存
                </Button>,
            ];
        } else if (isEdit) {
            headerExtra = [
                <LinkButton key={0} to={this.props.location.pathname.replace("/edit", "")}>
                    キャンセル
                </LinkButton>,
                <Button
                    key={1}
                    type={"primary"}
                    onClick={() =>
                        this.props.SettingStore!.putLine(
                            this.props.match.params.tenantId,
                            line.channel_id,
                            line.channel_secret,
                            line.long_term_token,
                        )
                    }
                >
                    保存
                </Button>,
            ];
        } else {
            headerExtra = [
                <LinkButton key={0} buttonProps={{ type: "primary" }} to={`${this.props.location.pathname}/edit`}>
                    編集
                </LinkButton>,
            ];
        }

        return (
            <div>
                <PageHeader title={<Typography.Title>LINE 設定</Typography.Title>} extra={headerExtra}>
                    <Descriptions size="small" column={3}>
                        <Descriptions.Item label="Messaging API Webhook エンドポイント">
                            <Typography.Paragraph copyable={true}>
                                {process.env.API_BASE_URL}/v1/tenants/{this.props.match.params.tenantId}/line/messaging
                            </Typography.Paragraph>
                        </Descriptions.Item>
                    </Descriptions>
                </PageHeader>
                <Form>
                    <Form.Item label={"チャネルID"}>
                        <Input
                            disabled={!isEdit}
                            value={this.props.SettingStore!.editLine.channel_id}
                            onChange={(e) => this.props.SettingStore!.updateEditLine({ ...line, channel_id: e.target.value })}
                        />
                    </Form.Item>
                    <Form.Item label={"チャネルシークレット"}>
                        <Input
                            disabled={!isEdit}
                            value={this.props.SettingStore!.editLine.channel_secret}
                            onChange={(e) => this.props.SettingStore!.updateEditLine({ ...line, channel_secret: e.target.value })}
                        />
                    </Form.Item>
                    <Form.Item label={"チャネルアクセストークン（長期）"}>
                        <Input.Password
                            disabled={!isEdit}
                            value={this.props.SettingStore!.editLine.long_term_token}
                            onChange={(e) => this.props.SettingStore!.updateEditLine({ ...line, long_term_token: e.target.value })}
                        />
                    </Form.Item>
                </Form>
            </div>
        );
    }
}
