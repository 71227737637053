import React from "react";
import { inject, observer } from "mobx-react";
import { Redirect, Route, RouteComponentProps, Router as ReactRouter, Switch } from "react-router";
import { createBrowserHistory } from "history";
import MobxReactRouter, { RouterStore, syncHistoryWithStore } from "mobx-react-router";
import Routes, { RouteInfo } from "../constants/Routes";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import LeftNav from "./LeftNav";
import { AuthStatus, AuthStore } from "../stores/AuthStore";
import { style } from "typestyle";
import {Plan, TenantStore} from "../stores/TenantStore";
import NotFound from "../NotFound";

export interface Props {
    AuthStore?: AuthStore;
    TenantStore?: TenantStore;
    RouterStore?: RouterStore;

    routes: RouteInfo[];
    notFound?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const styles = {
    wrapper: style({
        flex: 1,
        padding: "2rem",

        // HACK: 要らないはずだけど指定しておいたほうが描画が安定する
        maxWidth: "calc(100vw - 280px)",
    }),
};

@inject("AuthStore", "TenantStore", "RouterStore")
@observer
export default class Router extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);

        const history = createBrowserHistory();
        this.history = syncHistoryWithStore(history, this.props.RouterStore!);
        this.pathname = this.history.location.pathname;
        this.history.subscribe((location) => {
            if (location.pathname != this.pathname) {
                this.pathname = location.pathname;
                window.scrollTo(0, 0);
            }
        });

        this.routeArray = [];
    }

    public async componentDidMount() {
        // events.on("changeTenantId", (orgId: string) => {
        //     const current = this.props.RouterStore!.history.location.pathname;
        //     const next = current.replace(/\/tenants\/[^/]*/, `/tenants/${orgId}`);
        //     this.props.RouterStore!.history.push({ ...this.props.RouterStore!.history.location, pathname: next });
        // });
        await this.props.AuthStore!.checkAuth();
        if (!this.props.TenantStore!.tenants[this.props.AuthStore!.user.tenant]) {
            await this.props.TenantStore!.getTenant(this.props.AuthStore!.user.tenant);
            if (this.props.TenantStore!.tenants[this.props.AuthStore!.user.tenant]?.plan !== Plan.ROOT) {
                this.props.TenantStore!.select(this.props.AuthStore!.user.tenant);
            }
        }
    }

    private history: MobxReactRouter.SynchronizedHistory;
    private pathname: string;
    private routeArray: JSX.Element[];

    protected generateRoute(props: Props, routes: RouteInfo[]) {
        this.routeArray = [];
        this.parseRoute(props, routes);
        this.routeArray.push(<Route component={props.notFound || NotFound} key={this.routeArray.length} />);
    }

    protected parseRoute(props: Props, routes: RouteInfo[]) {
        const isRoot = this.props.TenantStore!.tenants[this.props.AuthStore!.user.tenant]?.plan === Plan.ROOT;
        routes.forEach((route) => {
            if (route.disableRootTenant === true && isRoot) {
                return;
            }
            if (route.path) {
                this.routeArray.push(
                    <Route
                        exact
                        path={route.path}
                        component={
                            route.component ? route.component : props.notFound || NotFound
                        }
                        key={this.routeArray.length}
                    />,
                );
            }
            if (route.children) {
                this.parseRoute(props, route.children);
            }
        });
    }

    public render() {
        this.generateRoute(this.props, this.props.routes);

        return (
            <ReactRouter {...this.props} history={this.history}>
                {this.props.AuthStore!.authStatus === AuthStatus.UNAUTHORIZED && !this.history.location.pathname.startsWith("/auth") && (
                    <Redirect to={"/auth/login"} />
                )}
                {this.props.AuthStore!.authStatus === AuthStatus.AUTHORIZED && <LeftNav routes={Routes} />}
                <OverlayScrollbarsComponent
                    className={styles.wrapper}
                    options={{
                        className: "os-theme-dark",
                        scrollbars: {
                            autoHide: "leave",
                            autoHideDelay: 300,
                        },
                    }}
                >
                    <Switch>{this.routeArray}</Switch>
                </OverlayScrollbarsComponent>
            </ReactRouter>
        );
    }
}
