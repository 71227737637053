import React from "react";
import { inject, observer } from "mobx-react";
import { style } from "typestyle";
import { AuthStore } from "../stores/AuthStore";
import {TenantStore, PlanString, Plan} from "../stores/TenantStore";
import { RouterStore } from "mobx-react-router";
import { Redirect, RouteChildrenProps } from "react-router";
import {Avatar, Button, Col, Drawer, Form, Row, Select, Spin, Typography} from "antd";
import Identicon from "../components/Identicon";
import Split from "../components/Split";
import {Input} from "antd/es";
import PullRight from "../components/PullRight";
import TextArea from "antd/es/input/TextArea";
import TenantDrawer from "./TenantDrawer";
import {WithRouteParamProps} from "../constants/Routes";

export interface Props extends WithRouteParamProps {
    AuthStore?: AuthStore;
    TenantStore?: TenantStore;
    RouterStore?: RouterStore;
}

const styles = {
    orgIcon: style({
        marginRight: "1rem",
        marginBottom: "1rem",
    }),
    name: style({
        marginBottom: "0 !important",
    }),
};

@inject("AuthStore", "TenantStore", "RouterStore")
@observer
export default class Tenant extends React.Component<Props, {}> {
    public componentDidMount() {
        this.props.TenantStore!.getTenant(this.props.match!.params.tenantId);
    }

    public render() {
        const store = this.props.TenantStore!;
        if (!store.tenants[this.props.match!.params.tenantId]) {
            return <Spin size={"large"} />;
        }

        const { id, name, description, plan } = store.tenants[this.props.match!.params.tenantId];
        const editTenant = store.editTenant;

        return (
            <>
                <Split>
                    <Col>
                        <Row>
                            <Avatar className={styles.orgIcon} shape={"square"} size={96} icon={<Identicon source={id} />} />

                            <Col>
                                <Typography.Title className={styles.name}>{name}</Typography.Title>
                                <Typography>ID: {id}</Typography>
                                <Typography>プラン: {PlanString[plan]}</Typography>
                            </Col>
                        </Row>
                        <Col>
                            <Typography>概要:</Typography>
                            {description.split("\n").map((line: string, index: number) => <p key={index}>{line}</p>)}
                        </Col>
                    </Col>
                    <Row>
                        {store.tenants[this.props.AuthStore!.user.tenant]?.plan === Plan.ROOT && !this.props.AuthStore!.isSpoofing && (
                            <>
                                <Button onClick={() => this.props.AuthStore!.startSpoofing(id)} style={{marginRight: 12}}>なりすまし管理</Button>
                                <Button type={"primary"} onClick={() => store.showEditDrawer(id)}>編集</Button>
                            </>
                        )}
                    </Row>
                </Split>
                <Drawer
                    key={store.selectedId}
                    title={"テナント編集"}
                    placement={"right"}
                    width={520}
                    closable={false}
                    visible={store.isVisibleEditDrawer}
                    footer={[
                        <PullRight key={1}>
                            <Button onClick={() => store.closeEditDrawer(false)}>
                                キャンセル
                            </Button>
                            <Button type={"primary"} onClick={() => store.closeEditDrawer(true)}>
                                保存
                            </Button>
                        </PullRight>
                    ]}
                >
                    <Form>
                        <Form.Item
                            name="name"
                            label="テナント名"
                            initialValue={editTenant.name}
                            rules={[{ required: true, message: "テナント名を入力してください" }]}
                        >
                            <Input
                                placeholder="分かりやすく完結な名前がベストです"
                                value={editTenant.name}
                                onChange={(e) => store.updateEditTenant({ ...editTenant, name: e.target.value })}
                            />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            label="概要"
                            initialValue={editTenant.description}
                            rules={[{ required: false }]}
                        >
                            <TextArea
                                placeholder="プロジェクトなどのメモを残すことができます"
                                autoSize={{ minRows: 4, maxRows: 10 }}
                                value={editTenant.description}
                                onChange={(e) => store.updateEditTenant({ ...editTenant, description: e.target.value })}
                            />
                        </Form.Item>
                        <Form.Item
                            name="plan"
                            label="プラン"
                            initialValue={editTenant.plan}
                            rules={[{ required: true, message: "プランを選択してください" }]}
                        >
                            <Select
                                placeholder="料金プランを選択します"
                                disabled={true}
                                value={editTenant.plan}
                            >
                                <Select.Option key={Plan.FREE} value={Plan.FREE}>
                                    βフリープラン
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </Drawer>
                <TenantDrawer
                    visible={false}
                    onClose={() => {
                        this.setState(
                            {
                                open: false,
                            },
                            async () => {
                                this.props.TenantStore!.getTenants();
                            },
                        );
                    }}
                />
            </>
        );
    }
}
