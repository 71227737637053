import React from "react";
import { Result } from "antd";
import LinkButton from "./components/LinkButton";

export default class NotFound extends React.Component<{}, {}> {
    public render() {
        return (
            <Result
                status="error"
                title="404"
                subTitle="ページが見つかりませんでした。"
                extra={
                    <LinkButton to={"/"} buttonProps={{ type: "primary" }}>
                        トップページ に戻る
                    </LinkButton>
                }
            />
        );
    }
}
