import { action, computed, observable } from "mobx";
import { ModelBase, OperationState, StoreBase } from "./Base";

export enum AuthStatus {
    INITIAL,
    UNAUTHORIZED,
    AUTHORIZED,
}

export enum Role {
    IOTA,
    ADMIN,
}

export interface UserModel extends ModelBase {
    name: string;
    email: string;
    role: Role;
    tenant: string;
}

interface AuthModel {
    user: UserModel;
    token: string;
}

export class AuthStore extends StoreBase {
    @observable
    public authStatus: AuthStatus;

    @observable
    public token: string;

    @observable
    public user: UserModel;

    @observable
    public originalTenantId: string;

    constructor() {
        super();

        this.user = {} as any;
        this.token = localStorage.token || "";
        this.authStatus = AuthStatus.INITIAL;
        this.originalTenantId = "";
    }

    @action
    public async login(email: string, password: string, keepSession: boolean) {
        this.setState(OperationState.RUNNING);

        try {
            const response = await fetch(this.apiPath("v1/auth"), {
                method: "POST",
                headers: this.generateFetchHeader(),
                body: JSON.stringify({
                    email,
                    password,
                }),
            });

            if (response.status !== 200) {
                throw new Error();
            }
            const result = await response.json() as AuthModel;
            this.token = result.token;
            this.user = result.user;

            if (keepSession) {
                localStorage.token = this.token;
            } else {
                sessionStorage.token = this.token;
            }

            this.tryShowToast("ログインしました", "success");
            this.authStatus = AuthStatus.AUTHORIZED;
            this.setState(OperationState.DONE);
        } catch (e) {
            this.tryShowToast("ログインに失敗しました", "error");
            console.error(e);
            this.setState(OperationState.ERROR);
        }
    }

    @action
    public logout() {
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        this.authStatus = AuthStatus.UNAUTHORIZED;
        this.token = "";
        this.user = {} as any;
    }

    @action
    public async checkAuth() {
        if (!this.token || this.token === "") {
            this.logout();
            return;
        }
        const response = await fetch(this.apiPath("v1/auth"), {
            headers: this.generateFetchHeader(),
        });
        if (response.status !== 200) {
            // this.tryShowToast("ログインセッション エラー");
            this.logout();
            return;
        }

        this.user = await response.json() as UserModel;
        this.authStatus = AuthStatus.AUTHORIZED;

        // HACK: 次のイベントループでやらないとダメなのですよ〜
        setTimeout(() => {
            if (localStorage.spoofingTenantId) {
                this.startSpoofing(localStorage.spoofingTenantId);
            }
        }, 0);
    }

    @computed
    public get name() {
        return this.user?.name || "";
    }

    @computed
    public get email() {
        return this.user?.email || "";
    }

    @action
    public startSpoofing(tenantId: string) {
        this.originalTenantId = this.user.tenant;
        this.user = {
            ...this.user,
            tenant: tenantId,
        };
        localStorage.setItem("spoofingTenantId", tenantId);
    }

    @action
    public endSpoofing() {
        this.user = {
            ...this.user,
            tenant: this.originalTenantId,
        };
        this.originalTenantId = "";
        localStorage.removeItem("spoofingTenantId");
    }

    @computed
    public get isSpoofing() {
        return this.originalTenantId !== "";
    }
}
