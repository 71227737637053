import { action } from "mobx";
import { OperationMode, OperationState } from "./Base";
import { UserModel, UserStore } from "./UserStore";
import Logger from "../util/Logger";

export class ActivateStore extends UserStore {
    constructor() {
        super();
    }

    @action
    public async activate(tmpToken: string, user?: UserModel) {
        this.setMode(OperationMode.UPDATE);
        this.setState(OperationState.RUNNING);

        try {
            const requestInit: RequestInit = {
                method: "PATCH",
                headers: this.generateFetchHeader(false),
            };
            if (user) {
                requestInit.body = JSON.stringify(user);
            }
            const response = await fetch(this.apiPath(`v1/activate/${tmpToken}`), requestInit);

            if (response.status === 428) {
                this.editItem = await response.json();
                this.setState(OperationState.NEED_ACTION);
                return;
            }

            if (response.status !== 200) {
                throw new Error();
            }
            this.setState(OperationState.DONE);
        } catch (e) {
            this.tryShowToast("アクティベートに失敗しました", "error");
            Logger.error("ActivateStore", "account activate error:", e);
            this.setState(OperationState.ERROR);
        }
    }
}
