export default {
    colors: {
        base: "#2f3f69",
        dark: "#253253",
        light: "#40558e",
        extraLight: "#516db4",
        superExtraLight: "#6885c7",
        ultraSuperExtraLight: "#7d93c7",
        white: "#fafafe",
        black: "#202020",
        darkGray: "#323240",
        extraDarkGray: "#5b5b69",
        superExtraDarkGray: "#6f6f7d",
        ultraSuperExtraDarkGray: "#838391",
        danger: "#ff4346",
        warn: "#e5cf3f",
        safe: "#74e589",
    },
};
