import React from "react";
import { inject, observer } from "mobx-react";
import { AuthStore } from "../stores/AuthStore";
import { GuestModel, GuestStore } from "../stores/GuestStore";
import { toJS } from "mobx";
import LinkButton from "../components/LinkButton";
import { Avatar, Empty, PageHeader, Table, Typography } from "antd";
import {WithRouteParamProps} from "../constants/Routes";

export interface Props extends WithRouteParamProps {
    AuthStore?: AuthStore;
    GuestStore?: GuestStore;
}

@inject("AuthStore", "GuestStore")
@observer
export default class Guest extends React.Component<Props, {}> {
    public componentDidMount() {
        this.props.GuestStore!.getGuests(this.props.match.params.tenantId);

    }

    public render() {
        const columns = [
            {
                title: "アバター",
                dataIndex: "_avatar",
                key: "_avatar",
            },
            {
                title: "名前",
                dataIndex: "name",
                key: "name",
            },
            {
                title: "LINE UID",
                dataIndex: "line_uid",
                key: "line_uid",
            },
            {
                title: "登録日時",
                dataIndex: "_created",
                key: "_created",
            },
            {
                title: "",
                dataIndex: "_buttons",
                key: "_buttons",
            },
        ];
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const dataSource = Object.values(toJS(this.props.GuestStore!.guests)).map(
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            (guest: GuestModel & { _avatar: any; _created: any; _buttons: any }) => {
                guest._avatar = <Avatar icon={<img src={guest.avatar} />} />;
                guest._created = <div>{new Date(guest.created).toLocaleString()}</div>;
                guest._buttons = (
                    <div>
                        <LinkButton to={`/tenants/${this.props.match.params.tenantId}/guests/${guest.id}`}>詳細</LinkButton>
                    </div>
                );
                return guest;
            },
        );
        console.log("dataSource:", dataSource);
        return (
            <div>
                <PageHeader title={<Typography.Title>ゲスト管理</Typography.Title>} />
                {dataSource.length === 0 ? <Empty /> : <Table columns={columns} dataSource={dataSource} />}
            </div>
        );
    }
}
