import React from "react";
import {inject, observer} from "mobx-react";
import {AuthStore} from "../stores/AuthStore";
import {toJS} from "mobx";
import LinkButton from "../components/LinkButton";
import {Empty, PageHeader, Table, Typography} from "antd";
import {WithRouteParamProps} from "../constants/Routes";
import {RoomModel, RoomState, RoomStore} from "../stores/RoomStore";

export interface Props extends WithRouteParamProps {
    AuthStore?: AuthStore;
    RoomStore?: RoomStore;
}

@inject("AuthStore", "RoomStore")
@observer
export default class Room extends React.Component<Props, {}> {
    public componentDidMount() {
        this.props.RoomStore!.list(this.props.match.params.tenantId);
    }

    public render() {
        const columns = [
            {
                title: "ID",
                dataIndex: "id",
                key: "id",
            },
            {
                title: "ルーム名",
                dataIndex: "name",
                key: "name",
            },
            {
                title: "状態",
                dataIndex: "_state",
                key: "_state",
            },
            {
                title: "作成日時",
                dataIndex: "_created",
                key: "_created",
            },
            {
                title: "",
                dataIndex: "_buttons",
                key: "_buttons",
            },
        ];
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const dataSource = Object.values(toJS(this.props.RoomStore!.items)).map(
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            (room: RoomModel & { _state: any; _created: any; _buttons: any }) => {
                switch (room.state) {
                    case RoomState.IOTA:
                        room._state = "-";
                        break;
                    case RoomState.OPEN:
                        room._state = "オープン";
                        break;
                    case RoomState.CLOSE:
                        room._state = "クローズ";
                        break;
                }
                room._created = <div>{new Date(room.created).toLocaleString()}</div>;
                room._buttons = (
                    <div>
                        <LinkButton to={`/tenants/${this.props.match.params.tenantId}/rooms/${room.id}`}>詳細</LinkButton>
                    </div>
                );
                return room;
            },
        );
        return (
            <div>
                <PageHeader title={<Typography.Title>ルーム管理</Typography.Title>} />
                {dataSource.length === 0 ? <Empty /> : <Table columns={columns} dataSource={dataSource} />}
            </div>
        );
    }
}
