import React from "react";
import { inject, observer } from "mobx-react";
import { AuthStore } from "../stores/AuthStore";
import { toJS } from "mobx";
import LinkButton from "../components/LinkButton";
import { Avatar, Empty, PageHeader, Table, Typography } from "antd";
import {WithRouteParamProps} from "../constants/Routes";
import {UserModel, UserStore} from "../stores/UserStore";
import {GuestModel} from "../stores/GuestStore";
import Identicon from "../components/Identicon";

export interface Props extends WithRouteParamProps {
    AuthStore?: AuthStore;
    UserStore?: UserStore;
}

@inject("AuthStore", "UserStore")
@observer
export default class User extends React.Component<Props, {}> {
    public componentDidMount() {
        this.props.UserStore!.list(this.props.match.params.tenantId);
    }

    public render() {
        const columns = [
            {
                title: "アバター",
                dataIndex: "_avatar",
                key: "_avatar",
            },
            {
                title: "名前",
                dataIndex: "name",
                key: "name",
            },
            {
                title: "ID",
                dataIndex: "id",
                key: "id",
            },
            {
                title: "登録日時",
                dataIndex: "_created",
                key: "_created",
            },
            {
                title: "",
                dataIndex: "_buttons",
                key: "_buttons",
            },
        ];
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const dataSource = Object.values(toJS(this.props.UserStore!.items)).map(
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            (user: UserModel & { _avatar: any; _created: any; _buttons: any }) => {
                user._avatar = <Avatar icon={<Identicon source={user.email} />} />;
                user._created = <div>{new Date(user.created).toLocaleString()}</div>;
                user._buttons = (
                    <div>
                        <LinkButton buttonProps={{disabled: true}} to={`/tenants/${this.props.match.params.tenantId}/users/${user.id}`}>詳細</LinkButton>
                    </div>
                );
                return user;
            },
        );
        return (
            <div>
                <PageHeader title={<Typography.Title>ユーザー管理</Typography.Title>} />
                {dataSource.length === 0 ? <Empty /> : <Table columns={columns} dataSource={dataSource} />}
            </div>
        );
    }
}
