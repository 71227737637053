import React from "react";
import { Avatar, Button, Divider, Menu } from "antd";
import { style } from "typestyle";
import { inject, observer } from "mobx-react";
import { RouterStore } from "mobx-react-router";
import { RouteInfo } from "../constants/Routes";
import { AuthStatus, AuthStore } from "../stores/AuthStore";
import { Link } from "react-router-dom";
import Logo from "../assets/hogeadmin.logo.white.svg";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import Identicon from "../components/Identicon";
import LogoutOutlined from "@ant-design/icons/LogoutOutlined";
import Theme from "../util/Theme";
import {Plan, TenantStore} from "../stores/TenantStore";

export interface Props {
    AuthStore?: AuthStore;
    TenantStore?: TenantStore;
    RouterStore?: RouterStore;
    routes: RouteInfo[];
}

interface State {}

const styles = {
    root: style({
        height: "100%",
        width: 280,
        display: "flex",
        flexDirection: "column",
        boxShadow: `1px 1px 4px -1px ${Theme.colors.darkGray}`,
    }),
    logo: style({
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        padding: "1rem 0",
        background: Theme.colors.base,
        $nest: {
            "& > svg": {
                height: 32,
            },
            "& > span": {
                marginLeft: "0.5em",
                color: Theme.colors.white,
            },
        },
    }),
    tenant: style({
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "42px",
        $nest: {
            "& > button": {
                height: "42px",
                padding: 0,
            },
            "& > button:first-child": {
                display: "flex",
                alignItems: "center",
                flex: 1,
            },
        },
    }),
    tenantName: style({
        display: "flex",
        flex: 1,
        paddingLeft: "0.5rem",
        $nest: {
            "& .ant-skeleton-title": {
                margin: 0,
            },
        },
    }),
    wrapper: style({
        flex: 1,
    }),
    menu: style({
        height: "100%",
        border: "none",
    }),
    divider: style({
        margin: "1px 0",
    }),
    user: style({
        display: "flex",
        padding: "0.5rem",
    }),
    avatar: style({
        backgroundColor: "rgba(0, 0, 0, 0.1)",
    }),
    info: style({
        flex: 1,
        paddingLeft: "0.5rem",
    }),
};

@inject("AuthStore", "TenantStore", "RouterStore")
@observer
export default class LeftNav extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.menuArray = [];
        this.selectedKeyFragment = "";
        this.generateMenu(props, props.routes);
    }

    private menuArray: JSX.Element[];
    private selectedKeyFragment: string;

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>) {
        this.generateMenu(nextProps, nextProps.routes);
        this.getTenant();
    }

    protected generateMenu(props: Props, routes: RouteInfo[]) {
        this.menuArray = this.parseRoute(props, routes);
        this.forceUpdate();
    }

    private async getTenant() {
        if (!this.props.TenantStore!.tenants[this.props.AuthStore!.user.tenant]) {
            await this.props.TenantStore!.getTenant(this.props.AuthStore!.user.tenant);
            if (this.props.TenantStore!.tenants[this.props.AuthStore!.user.tenant]?.plan !== Plan.ROOT) {
                this.props.TenantStore!.select(this.props.AuthStore!.user.tenant);
            }
        }
    }

    private replacePath(path: string) {
        return path.replace(":tenantId", this.props.AuthStore!.user.tenant);
    }

    protected parseRoute(props: Props, routes: RouteInfo[]): JSX.Element[] {
        const isRoot = this.props.TenantStore!.tenants[this.props.AuthStore!.user.tenant]?.plan === Plan.ROOT;
        return routes.map((route, index) => {
            if (route.disableRootTenant === true && isRoot) {
                return <React.Fragment key={route.name || index}/>;
            }

            if (route.disableRootTenant === false && !isRoot) {
                return <React.Fragment key={route.name || index}/>;
            }

            if (!route.path || route.hideLeftNav) {
                return <React.Fragment key={route.name || index}/>;
            }

            const path = this.replacePath(route.path);

            if (route.children) {
                return (
                    <Menu.SubMenu key="sub3" title={route.name || path}>
                        {this.parseRoute(props, route.children)}
                    </Menu.SubMenu>
                );
            }

            return (
                <Menu.Item key={path}>
                    <Link to={path}>{route.name || path}</Link>
                </Menu.Item>
            );
        });
    }

    protected parsePathFragment(routes: RouteInfo[]) {
        return routes
            .filter((route) => !route.hideLeftNav)
            .forEach((route) => {
                if (!route.path) {
                    return;
                }

                const path = this.replacePath(route.path);

                this.selectedKeyFragment = this.props.RouterStore!.location.pathname.includes(path) ? path : this.selectedKeyFragment;

                if (route.children) {
                    this.parsePathFragment(route.children);
                }
            });
    }

    public render() {
        if (this.props.AuthStore!.authStatus !== AuthStatus.AUTHORIZED) {
            return <></>;
        }

        this.selectedKeyFragment = "";
        this.parsePathFragment(this.props.routes);
        console.debug("LeftNav active:", this.selectedKeyFragment);

        return (
            <div className={styles.root}>
                <div className={styles.logo}>
                    <Logo />
                    <span>(仮)</span>
                </div>
                <OverlayScrollbarsComponent
                    className={styles.wrapper}
                    options={{
                        className: "os-theme-dark",
                        scrollbars: {
                            autoHide: "leave",
                            autoHideDelay: 300,
                        },
                    }}
                >
                    <Menu className={styles.menu} selectedKeys={[this.props.RouterStore!.location.pathname, this.selectedKeyFragment]}>
                        {this.menuArray}
                    </Menu>
                </OverlayScrollbarsComponent>
                {this.props.AuthStore!.isSpoofing && (
                    <>
                        <Divider className={styles.divider} />
                        <div style={{background: "#ff4040", color: "white"}}>
                            <div>
                                なりすまし管理中: {this.props.TenantStore!.tenants[this.props.AuthStore!.user.tenant]?.name || "-"}
                            </div>
                            <Button onClick={() => this.props.AuthStore!.endSpoofing()}>終了</Button>
                        </div>
                    </>
                )}
                <Divider className={styles.divider} />
                <div className={styles.user}>
                    <Avatar className={styles.avatar} size={48} icon={<Identicon source={this.props.AuthStore!.email} />} />
                    <div className={styles.info}>
                        <div>{this.props.AuthStore!.name}</div>
                        <div>{this.props.AuthStore!.email}</div>
                    </div>
                    <Button shape="circle" icon={<LogoutOutlined />} onClick={() => this.props.AuthStore!.logout()} />
                </div>
            </div>
        );
    }
}
