import React from "react";
import {inject, observer} from "mobx-react";
import {style} from "typestyle";
import {AuthStore} from "../stores/AuthStore";
import {Plan, PlanString, TenantStore} from "../stores/TenantStore";
import {RouterStore} from "mobx-react-router";
import {Button, Empty, PageHeader, Table, Typography} from "antd";
import {toJS} from "mobx";
import {WithRouteParamProps} from "../constants/Routes";
import Identicon from "../components/Identicon";
import TenantDrawer from "./TenantDrawer";
import LinkButton from "../components/LinkButton";

export interface Props extends WithRouteParamProps {
    AuthStore?: AuthStore;
    TenantStore?: TenantStore;
    RouterStore?: RouterStore;
}

interface State {
    open: boolean;
}

const styles = {
    orgIcon: style({
        marginRight: "1rem",
        marginBottom: "1rem",
    }),
    name: style({
        marginBottom: "0 !important",
    }),
};

@inject("AuthStore", "TenantStore", "RouterStore")
@observer
export default class TenantList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    public componentDidMount() {
        this.props.TenantStore!.getTenants();
    }

    public render() {
        const dataSource = Object.values(toJS(this.props.TenantStore!.tenants)).filter((tenant) => tenant.plan !== Plan.ROOT).map((tenant) => {
            return {
                ...tenant,
                plan: PlanString[tenant.plan],
                icon: <Identicon source={tenant.id} size={48} />,
                detailButton: <LinkButton to={`/tenants/${tenant.id}`}>詳細</LinkButton>,
            }
        });
        console.log(toJS(this.props.TenantStore!.tenants), dataSource);
        const columns = [
            {
                title: "",
                dataIndex: "icon",
            },
            {
                title: "ID",
                dataIndex: "id",
            },
            {
                title: "名前",
                dataIndex: "name",
            },
            {
                title: "プラン",
                dataIndex: "plan",
            },
            {
                title: "",
                dataIndex: "detailButton",
            }
        ];

        return (
            <>
                <PageHeader title={<Typography.Title>テナント管理</Typography.Title>} extra={<Button type={"primary"} onClick={() => this.setState({open: true})}>テナント作成</Button>} />
                {dataSource.length === 0 ? <Empty/> : <Table dataSource={dataSource} columns={columns} />}
                <TenantDrawer visible={this.state.open} onClose={() => this.setState({open: false})} />
            </>
        );
    }
}
