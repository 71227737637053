import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import { ConfigProvider } from "antd";
import "overlayscrollbars/css/OverlayScrollbars.css";
import { style } from "typestyle";
import Store from "./stores";
import Router from "./containers/Router";
import Routes from "./constants/Routes";

const styles = {
    root: style({
        height: "100vh",
        width: "100vw",
    }),
    wrapper: style({
        display: "flex",
        height: "100vh",
    }),
    main: style({
        flex: 1,
    }),
};

export function render() {
    ReactDOM.render(
        <div className={styles.root}>
            <ConfigProvider>
                <Provider {...Store}>
                    <div className={styles.wrapper}>
                        <Router routes={Routes} />
                    </div>
                </Provider>
            </ConfigProvider>
        </div>,
        document.querySelector("#app"),
    );
}
