import { action, computed, observable } from "mobx";
import { ItemStore, ModelBase, OperationMode, OperationState } from "./Base";
import Logger from "../util/Logger";

export interface RoomModel extends ModelBase {
    name: string;
    description: string;
    tenant: string;
    state: RoomState;
    guest: string;
    user: string;

    created: string;
    updated: string;
}

export interface RoomWithMessageModel extends RoomModel {
    messages: MessageModel[];
}

export interface MessageModel extends ModelBase {
    room: string;
    guest: string;
    user: string;
    body: string;
    raw: string;
}

export enum RoomState {
    IOTA,
    OPEN,
    CLOSE,
}

export class RoomStore extends ItemStore<RoomModel> {
    @action
    public async list(tenantId: string) {
        this.setMode(OperationMode.LIST);
        this.setState(OperationState.RUNNING);

        try {
            const response = await fetch(this.apiPath(`v1/tenants/${tenantId}/rooms`), {
                method: "GET",
                headers: this.generateFetchHeader(),
            });

            if (response.status !== 200) {
                throw new Error();
            }
            this.items = await response.json();
            this.setState(OperationState.DONE);
        } catch (e) {
            this.tryShowToast("ルームの取得に失敗しました", "error");
            Logger.error("UserStore", "users fetch error:", e);
            this.setState(OperationState.ERROR);
        }
    }

    @action
    public async get(tenantId: string, roomId: string) {
        this.setMode(OperationMode.LIST);
        this.setState(OperationState.RUNNING);

        try {
            const response = await fetch(this.apiPath(`v1/tenants/${tenantId}/rooms/${roomId}`), {
                method: "GET",
                headers: this.generateFetchHeader(),
            });

            if (response.status !== 200) {
                throw new Error();
            }
            this.editItem = await response.json();
            this.setState(OperationState.DONE);
        } catch (e) {
            this.tryShowToast("ルームの取得に失敗しました", "error");
            Logger.error("UserStore", "users fetch error:", e);
            this.setState(OperationState.ERROR);
        }
    }
}
