import { action, computed, observable } from "mobx";
import { ItemStore, ModelBase, OperationMode, OperationState } from "./Base";
import stores from "./index";
import Logger from "../util/Logger";

export interface UserModel extends ModelBase {
    name: string;
    email: string;
    tenant: string;
    role: UserRole;
    created: string;
    updated: string;

    password: string;
}

export enum UserRole {
    IOTA,
    ADMIN,
}

export class UserStore extends ItemStore<UserModel> {
    @observable
    public isVisibleEditDrawer: boolean;

    @observable
    public isVisibleCreateDrawer: boolean;

    constructor() {
        super();

        this.isVisibleEditDrawer = false;
        this.isVisibleCreateDrawer = false;
    }

    @action
    public async list(tenantId: string) {
        this.setMode(OperationMode.LIST);
        this.setState(OperationState.RUNNING);

        try {
            const response = await fetch(this.apiPath(`v1/tenants/${tenantId}/users`), {
                method: "GET",
                headers: this.generateFetchHeader(),
            });

            if (response.status !== 200) {
                throw new Error();
            }
            this.items = await response.json();
            this.setState(OperationState.DONE);
        } catch (e) {
            this.tryShowToast("ユーザーの取得に失敗しました", "error");
            Logger.error("UserStore", "users fetch error:", e);
            this.setState(OperationState.ERROR);
        }
    }

    @action
    public async getEditUser(tenantId: string, userId: string) {
        if (!tenantId || tenantId === "" || !userId || userId === "") {
            return;
        }

        this.setState(OperationState.RUNNING);

        try {
            const response = await fetch(this.apiPath(`v1/tenants/${tenantId}/users/${userId}`), {
                method: "GET",
                headers: this.generateFetchHeader(),
            });

            if (response.status !== 200) {
                throw new Error();
            }
            this.editItem = await response.json();
        } catch (e) {
            this.tryShowToast("ユーザーの取得に失敗しました", "error");
            Logger.error("UserStore", "user fetch error:", e);
            this.setState(OperationState.ERROR);
        }
    }

    @action
    public async openCreateDrawer(initialRole: UserRole, shouldPush = true) {
        this.editItem = {
            email: "",
            role: initialRole,
        };
        if (shouldPush) {
            stores.RouterStore.push(`${stores.RouterStore.location.pathname}/-/create`);
        }
        this.isVisibleCreateDrawer = true;
    }

    @action
    public async openEditDrawer(tenantId: string, userId: string, shouldPush = true) {
        await this.getEditUser(tenantId, userId);
        if (shouldPush) {
            stores.RouterStore.push(`${stores.RouterStore.location.pathname}/${userId}/edit`);
        }
        this.isVisibleEditDrawer = true;
    }

    @action
    public async closeCreateDrawer(shouldCreate: boolean, tenantId = "") {
        if (!shouldCreate) {
            this.isVisibleCreateDrawer = false;

            this.resetEditItem();
            return;
        }

        this.setMode(OperationMode.CREATE);
        this.setState(OperationState.RUNNING);

        // eslint-disable-next-line @typescript-eslint/camelcase
        const { email, role } = this.editItem;

        try {
            const response = await fetch(this.apiPath(`v1/tenants/${tenantId}/users`), {
                method: "POST",
                headers: this.generateFetchHeader(),
                body: JSON.stringify({
                    email,
                    role,
                }),
            });

            if (response.status !== 200) {
                throw new Error();
            }
            this.setState(OperationState.DONE);
        } catch (e) {
            this.tryShowToast("ユーザーの作成に失敗しました", "error");
            Logger.error("UserStore", "user create error:", e);
            this.setState(OperationState.ERROR);
        }

        this.isVisibleCreateDrawer = false;
        this.resetEditItem();
    }

    @action
    public async closeEditDrawer(shouldUpdate: boolean, tenantId = "", userId = "") {
        if (!shouldUpdate) {
            this.isVisibleEditDrawer = false;
            this.resetEditItem();
            return;
        }

        const { email, role } = this.editItem;

        try {
            const response = await fetch(this.apiPath(`v1/tenants/${tenantId}/users/${userId}`), {
                method: "PATCH",
                headers: this.generateFetchHeader(),
                body: JSON.stringify({
                    email,
                    role,
                }),
            });

            if (response.status !== 200) {
                throw new Error();
            }

            this.isVisibleEditDrawer = false;
            this.resetEditItem();
        } catch (e) {
            this.tryShowToast("ユーザーの更新に失敗しました", "error");
            Logger.error("UserStore", "user update error:", e);
            this.setState(OperationState.ERROR);
        }
    }

    @action
    public async deleteElementAndCloseDrawer(tenantId: string, userId: string) {
        this.setState(OperationState.RUNNING);

        try {
            const response = await fetch(this.apiPath(`v1/tenants/${tenantId}/users/${userId}`), {
                method: "DELETE",
                headers: this.generateFetchHeader(),
            });

            if (response.status !== 200) {
                throw new Error();
            }
            this.closeEditDrawer(false);
            this.setState(OperationState.DONE);
        } catch (e) {
            this.tryShowToast("ユーザーの削除に失敗しました", "error");
            Logger.error("UserStore", "user delete error:", e);
            this.setState(OperationState.ERROR);
        }
    }

    @computed
    public get isInvalidEditUserState() {
        return false;
    }
}
