import React from "react";
import { inject, observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import { ActivateStore } from "../stores/ActivateStore";
import { RouterStore } from "mobx-react-router";
import {Button, Form, Input, Modal, PageHeader, Result, Spin, Typography} from "antd";
import { OperationMode, OperationState } from "../stores/Base";
import { AuthStore } from "../stores/AuthStore";
import { UserModel } from "../stores/UserStore";
import {style} from "typestyle";
import Logo from "../assets/hogeadmin.logo.color.svg";

type RouterProps = RouteComponentProps<{ tmpToken: string }>;

export interface Props extends RouterProps {
    RouterStore?: RouterStore;
    AuthStore?: AuthStore;
    ActivateStore?: ActivateStore;
}

const styles = {
    root: style({
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 10,
        width: "100vw",
        height: "100vh",
        background: "white",
    }),
    logo: style({
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        marginBottom: "2rem",
        $nest: {
            "& > svg": {
                height: 32,
            },
            "& > span": {
                marginLeft: "0.5em",
            },
        },
    }),
}

@inject("RouterStore", "AuthStore", "ActivateStore")
@observer
export default class Activate extends React.Component<Props, {}> {
    public componentDidMount() {
        this.props.ActivateStore!.activate(this.props.match.params.tmpToken);
    }

    private initializeModal() {
        return (
            <Modal
                title={"アカウント 初期設定"}
                visible={
                    this.props.ActivateStore!.mode === OperationMode.UPDATE &&
                    this.props.ActivateStore!.state === OperationState.NEED_ACTION
                }
                closable={false}
                footer={[
                    <Button
                        key={1}
                        type={"primary"}
                        onClick={() => {
                            this.props.ActivateStore!.activate(
                                this.props.match.params.tmpToken,
                                this.props.ActivateStore!.editItem as UserModel,
                            );
                        }}
                    >
                        送信
                    </Button>,
                ]}
            >
                <Form autoComplete={"off"}>
                    <Form.Item label={"メール アドレス"}>
                        <Input value={this.props.ActivateStore!.editItem.email} disabled={true} />
                    </Form.Item>
                    <Form.Item label={"パスワード"} name={"password"} rules={[{ required: true, message: "パスワードは必須です" }]}>
                        <Input.Password
                            onChange={(e) =>
                                this.props.ActivateStore!.updateEditItem({
                                    ...this.props.ActivateStore!.editItem,
                                    password: e.target.value,
                                })
                            }
                        />
                    </Form.Item>
                    <Form.Item label={"名前"} name={"name"} rules={[{ required: true, message: "名前は必須です" }]}>
                        <Input
                            onChange={(e) =>
                                this.props.ActivateStore!.updateEditItem({
                                    ...this.props.ActivateStore!.editItem,
                                    name: e.target.value,
                                })
                            }
                        />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }

    private navbar() {
        return (
            <PageHeader title={
                <div className={styles.logo}>
                    <Logo/>
                </div>
            } />
        );
    }

    public render() {
        if (this.props.ActivateStore!.mode === OperationMode.UPDATE && this.props.ActivateStore!.state === OperationState.DONE) {
            setTimeout(() => {
                this.props.AuthStore!.logout();
                this.props.history.push("/");
            }, 3000);
            return (
                <div className={styles.root}>
                    {this.navbar()}
                    <Result
                        status={"success"}
                        title="アクティベーションしました"
                        extra={<Typography>リダイレクトしています...</Typography>}
                    />
                    {this.initializeModal()}
                </div>
            );
        }

        if (this.props.ActivateStore!.mode === OperationMode.UPDATE && this.props.ActivateStore!.state === OperationState.ERROR) {
            return (
                <div className={styles.root}>
                    {this.navbar()}
                    <Result
                        status={"error"}
                        title="アクティベーション エラー"
                        extra={<Typography>しばらくしてから、もう一度お試しください。</Typography>}
                    />
                </div>
            );
        }

        return (
            <div className={styles.root}>
                {this.navbar()}
                <Result status={"info"} title="アクティベーションしています" extra={<Spin />} />
                {this.initializeModal()}
            </div>
        );
    }
}
