import {action, computed, observable, toJS} from "mobx";
import notification, { IconType, NotificationPlacement } from "antd/es/notification";
import { TablePaginationConfig } from "antd/lib/table/interface";
import Logger from "../util/Logger";

export enum OperationState {
    IDLE,
    RUNNING,
    DONE,
    ERROR,
    NEED_ACTION,
}

export enum OperationMode {
    NOOP,
    CREATE,
    READ,
    UPDATE,
    DELETE,
    LIST,
}

export interface ModelBase {
    id: string;
    created: string;
    updated: string;
}

export class StoreBase {
    constructor() {
        this.state = OperationState.IDLE;
        this.mode = OperationMode.NOOP;
    }

    @observable
    public state: OperationState;

    @observable
    public mode: OperationMode;

    @action
    public setState(state: OperationState) {
        this.state = state;
    }

    @action
    public resetState() {
        this.state = OperationState.IDLE;
    }

    @action
    public setMode(mode: OperationMode) {
        this.mode = mode;
    }

    @action
    public resetMode() {
        this.mode = OperationMode.NOOP;
    }

    protected apiPath(path: string) {
        return process.env.API_BASE_URL + "/" + path;
    }

    protected generateFetchHeader(withAuth = true, override?: HeadersInit) {
        const baseHeader = {
            Accept: "application/json",
            "Content-Type": "application/json",
            "If-Modified-Since": "Thu, 01 Jun 1970 00:00:00 GMT",
        };
        let result = { ...baseHeader };
        if (withAuth) {
            result = {
                ...result,
                ...{
                    Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
                },
            };
        }
        if (override) {
            result = {
                ...result,
                ...override,
            };
        }

        return result;
    }

    protected get isLoggedIn() {
        return !!localStorage.token || !!sessionStorage.token || false;
    }

    protected tryShowToast(message: string, type: IconType | "open" = "open", placement: NotificationPlacement = "bottomLeft") {
        Logger.silly("BaseStore", "tryShowToast", "type", placement, message);
        notification[type]({
            message,
            placement,
        });
    }
}

export class ItemStore<T> extends StoreBase {
    @observable
    public items: T[];

    @observable
    public editItem: Partial<T>;

    @observable
    public filterValue: string;

    @observable
    public pagination: TablePaginationConfig;

    constructor() {
        super();
        this.items = [];
        this.editItem = {};
        this.filterValue = "";
        this.pagination = { current: 1, pageSize: 10 };
    }

    @computed
    public get isInvalidEditItemState() {
        return false;
    }

    @action
    public resetEditItem() {
        this.editItem = {};
    }

    @action
    public updateEditItem(item: Partial<T>) {
        this.editItem = { ...this.editItem, ...item };
    }

    @action
    public updateFilterValue(value: string) {
        this.filterValue = value;
        this.pagination = { ...this.pagination, current: 1 };
    }

    @computed
    public get filteredItems(): T[] {
        const targetValue = this.filterValue.trim();
        if (targetValue === "") {
            return this.items;
        }
        return this.items.filter((item) =>
            Object.keys(item)
                .filter((key) => !key.includes("created") && !key.includes("updated"))
                .map((key) => (item as any)[key])
                .some((mobXvalue) => {
                    const value = toJS(mobXvalue);
                    return (
                        (typeof value == "string" && value.includes(targetValue)) ||
                        (Array.isArray(value) && value.filter((childValue) => childValue.includes(targetValue)).length !== 0)
                    );
                }),
        );
    }

    @action
    public onChangeTable(pagination: TablePaginationConfig) {
        this.pagination = pagination || { current: 1, pageSize: 10 };
    }
}
