import mitt from "mitt";
import StrictEventEmitter from "strict-event-emitter-types";
import { RouterStore } from "mobx-react-router";
import { AuthStore } from "./AuthStore";
import { TenantStore } from "./TenantStore";
import { SettingStore } from "./SettingStore";
import { GuestStore } from "./GuestStore";
import { UserStore } from "./UserStore";
import { ActivateStore } from "./ActivateStore";
import {RoomStore} from "./RoomStore";

export interface TypedEvents {
    changeTenantId: (orgId: string) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const events: StrictEventEmitter<mitt.Emitter, TypedEvents> = mitt();
export { events };

const stores = {
    RouterStore: new RouterStore(),
    AuthStore: new AuthStore(),
    TenantStore: new TenantStore(),
    SettingStore: new SettingStore(),
    GuestStore: new GuestStore(),
    UserStore: new UserStore(),
    ActivateStore: new ActivateStore(),
    RoomStore: new RoomStore(),
};
export default stores;
